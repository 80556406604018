import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { sendPasswordResetEmail, confirmPasswordReset } from 'firebase/auth';
import { auth } from '../../firebase-config';
import '../../App.css';

function PasswordReset({ actionCode, email, onSubmit }) {
  const [resetEmail, setResetEmail] = useState(email || '');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleReset = async (e) => {
    e.preventDefault();
    if (actionCode) {
      // Confirm password reset
      if (newPassword !== confirmPassword) {
        setError('Passwords do not match.');
        return;
      }
      try {
        await confirmPasswordReset(auth, actionCode, newPassword);
        setMessage('Password reset successful.');
        setTimeout(() => navigate('/login'), 3000);
      } catch (error) {
        setError(error.message);
      }
    } else {
      // Send password reset email
      try {
        await sendPasswordResetEmail(auth, resetEmail);
        setMessage('Password reset email sent. Check your inbox.');
        setError('');
      } catch (error) {
        setError(error.message);
        setMessage('');
      }
    }
  };

  return (
    <div className="auth-container">
      <h2 className="auth-title">{actionCode ? 'Reset Your Password' : 'Request Password Reset'}</h2>
      <form onSubmit={handleReset} className="auth-form">
        {!actionCode && (
          <input
            className="auth-input"
            type="email"
            value={resetEmail}
            onChange={(e) => setResetEmail(e.target.value)}
            placeholder="Email"
            required
          />
        )}
        {actionCode && (
          <>
            <input
              className="auth-input"
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="New Password"
              required
            />
            <input
              className="auth-input"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm New Password"
              required
            />
          </>
        )}
        <button className="auth-button" type="submit">
          {actionCode ? 'Reset Password' : 'Send Reset Email'}
        </button>
      </form>
      {message && <p className="auth-message">{message}</p>}
      {error && <p className="auth-error">{error}</p>}
      <p className="auth-link">
        Remember your password? <Link to="/login">Login here</Link>
      </p>
    </div>
  );
}

export default PasswordReset;
