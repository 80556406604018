import { loadStripe } from '@stripe/stripe-js';
import { db, functions } from '../firebase-config';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';

export const MAX_FREE_SIZE = 75 * 1024 * 1024; // 75MB
export const MAX_PREMIUM_SIZE = 150 * 1024 * 1024; // 150MB

export const createCheckoutSession = async (userId) => {
  try {
    const createStripeCheckoutSession = httpsCallable(functions, 'createStripeCheckoutSession');
    const result = await createStripeCheckoutSession({ userId });
    const { sessionId, publishableKey } = result.data;

    if (sessionId && publishableKey) {
      const stripe = await loadStripe(publishableKey);
      if (stripe) {
        const { error } = await stripe.redirectToCheckout({ sessionId });
        if (error) {
          throw new Error(error.message);
        }
      } else {
        throw new Error('Failed to initialize Stripe');
      }
    } else {
      throw new Error('Failed to create checkout session');
    }
  } catch (error) {
    console.error('Error in createCheckoutSession:', error);
    throw error;
  }
};

export const isUserPremium = async (userId) => {
  const userRef = doc(db, 'users', userId);
  const userSnap = await getDoc(userRef);
  
  if (userSnap.exists()) {
    const userData = userSnap.data();
    return userData.isPremium || false;
  }
  
  return false;
};

export const updateUserPremiumStatus = async (userId, isPremium) => {
  const userRef = doc(db, 'users', userId);
  await updateDoc(userRef, { isPremium });
};
