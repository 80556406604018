import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import { getFunctions } from 'firebase/functions';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getDatabase } from 'firebase/database';
import { getAnalytics } from 'firebase/analytics';

// import { getMessaging } from 'firebase/messaging';
// import { getPerformance } from 'firebase/performance';
// import { getRemoteConfig } from 'firebase/remote-config';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  appId: process.env.REACT_APP_ID,
  databaseURL: process.env.REACT_APP_DATABASE_URL
};

const environment = process.env.REACT_APP_ENVIRONMENT || 'development';
console.log('Current environment:', environment);

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app);
export const database = getDatabase(app);
const analytics = getAnalytics(app);

// If you're using Firebase emulators for local development, uncomment this:
// if (environment === 'development') {
//   connectFunctionsEmulator(functions, "localhost", 5001);
// }

//   const messaging = getMessaging(app);

//   const performance = getPerformance(app);
//   const remoteConfig = getRemoteConfig(app);

export { app, storage, functions, auth, db, analytics, firebaseConfig };