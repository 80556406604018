import React from 'react';
import './ProgressDisplay.css';

function ProgressDisplay({ progress, stage }) {
  const getDisplayStage = (stage) => {
    if (!stage) return 'Processing'; // Default value if stage is undefined
    switch (stage.toLowerCase()) {
      case 'uploading':
        return 'Uploading';
      case 'initializing':
        return 'Initializing';
      case 'transcribing':
        return 'Transcribing media';
      case 'analyzing transcript':
        return 'Analyzing transcript';
      case 'selecting highlights':
        return 'Selecting highlights';
      case 'generating highlights json':
        return 'Generating highlights';
      case 'preparing response':
        return 'Preparing response';
      case 'extracting highlights':
        return 'Extracting highlights';
      default:
        return stage;
    }
  };

  return (
    <div className="progress-display">
      <p>{getDisplayStage(stage)}: {progress}%</p>
    </div>
  );
}

export default ProgressDisplay;