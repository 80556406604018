import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { applyActionCode, checkActionCode, confirmPasswordReset, verifyPasswordResetCode } from 'firebase/auth';
import { auth } from '../../firebase-config';
import LoadingSpinner from '../LoadingSpinner';
import PasswordReset from '../PasswordReset';
import '../../App.css';

const EmailActionHandler = () => {
    const [error, setError] = useState(null);
    const [info, setInfo] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const handleAction = async () => {
            const urlParams = new URLSearchParams(location.search);
            const mode = urlParams.get('mode');
            const actionCode = urlParams.get('oobCode');

            console.log('EmailActionHandler triggered:', { mode, actionCode });

            if (!mode || !actionCode) {
                console.error('Invalid action: missing mode or actionCode');
                setError('Invalid action.');
                return;
            }

            try {
                switch (mode) {
                    case 'resetPassword':
                        console.log('Handling resetPassword');
                        const email = await verifyPasswordResetCode(auth, actionCode);
                        console.log('Password reset code verified for:', email);
                        setInfo({ mode, actionCode, email });
                        break;
                    case 'recoverEmail':
                        console.log('Handling recoverEmail');
                        const checkResult = await checkActionCode(auth, actionCode);
                        console.log('Action code checked:', checkResult);
                        setInfo({ mode, actionCode, data: checkResult.data });
                        break;
                    case 'verifyEmail':
                        console.log('Handling verifyEmail');
                        await checkActionCode(auth, actionCode);
                        console.log('Email verification code checked');
                        setInfo({ mode, actionCode });
                        break;
                    default:
                        console.error('Invalid action mode:', mode);
                        setError('Invalid action mode.');
                }
            } catch (error) {
                console.error('Error handling action:', error);
                setError(error.message);
            }
        };

        handleAction();
    }, [location]);

    const handleResetPassword = async (newPassword) => {
        try {
            await confirmPasswordReset(auth, info.actionCode, newPassword);
            console.log('Password reset successful');
            navigate('/login', { state: { message: 'Password reset successful. You can now log in with your new password.' } });
        } catch (error) {
            console.error('Error resetting password:', error);
            setError(error.message);
        }
    };

    const handleRecoverEmail = async () => {
        try {
            await applyActionCode(auth, info.actionCode);
            console.log('Email recovered successfully');
            navigate('/login', { state: { message: 'Your email has been restored. You can now log in with your original email.' } });
        } catch (error) {
            console.error('Error recovering email:', error);
            setError(error.message);
        }
    };

    const handleVerifyEmail = async () => {
        try {
            await applyActionCode(auth, info.actionCode);
            console.log('Email verified successfully');
            navigate('/login', { state: { message: 'Your email has been verified. You can now log in.' } });
        } catch (error) {
            console.error('Error verifying email:', error);
            setError(error.message);
        }
    };

    if (error) {
        return <div className="auth-error">{error}</div>;
    }

    if (!info) {
        return <LoadingSpinner />;
    }

    switch (info.mode) {
        case 'resetPassword':
            return <PasswordReset email={info.email} actionCode={info.actionCode} onSubmit={handleResetPassword} />;
        case 'recoverEmail':
            return (
                <div className="auth-container">
                    <h2 className="auth-title">Recover Email</h2>
                    <p className="auth-description">Your email will be changed back to {info.data.email}.</p>
                    <button className="auth-button" onClick={handleRecoverEmail}>Confirm</button>
                </div>
            );
        case 'verifyEmail':
            return (
                <div className="auth-container">
                    <h2 className="auth-title">Verify Email</h2>
                    <p className="auth-description">Click the button below to verify your email.</p>
                    <button className="auth-button" onClick={handleVerifyEmail}>Verify Email</button>
                </div>
            );
        default:
            return <div className="auth-error">Invalid action.</div>;
    }
};

export default EmailActionHandler;