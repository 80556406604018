import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { AuthProvider, useAuth } from './context/AuthContext';
import Login from './components/Login';
import Register from './components/Register';
import FileUpload from './components/FileUpload';
import PasswordReset from './components/PasswordReset';
import PrivateRoute from './components/PrivateRoute';
import Upgrade from './components/Upgrade';
import LoadingSpinner from './components/LoadingSpinner';
import NotFound from './components/NotFound';
import EmailActionHandler from './components/EmailActionHandler';
import GoogleAnalytics from './components/GoogleAnalytics';
import './App.css';
import { AnalyticsProvider } from './context/AnalyticsContext';
import SEO from './components/SEO/SEO'; 

function AppRoutes() {
  const { loading } = useAuth();

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="App">
      <SEO title="Home" description="Pocket Producer - Your audio production assistant" keywords="audio, production, AI" />
      <GoogleAnalytics />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/reset-password" element={<PasswordReset />} />
        <Route path="/" element={<PrivateRoute> <FileUpload /> </PrivateRoute>} />
        <Route path="/upgrade" element={<PrivateRoute> <Upgrade /> </PrivateRoute>} />
        <Route path="/__/auth/action" element={<EmailActionHandler />} />
        <Route path="/404" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
    </div>
  );
}

function App() {
  return (
    <HelmetProvider>
      <AnalyticsProvider>
        <AuthProvider>
          <AppRoutes />
        </AuthProvider>
      </AnalyticsProvider>
    </HelmetProvider>
  );
}

export default App;
