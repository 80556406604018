import React, { useState } from 'react';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { auth, db } from '../../firebase-config';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { getFunctions, httpsCallable } from 'firebase/functions';
import '../../App.css';

function Register() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { setUser } = useAuth();

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      setError(null);
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      await sendEmailVerification(userCredential.user);
      const user = userCredential.user;
      
      // Create a user document in Firestore
      await setDoc(doc(db, 'users', user.uid), {
        email: user.email,
        isPremium: false,
        createdAt: new Date()
      });

      // Update the user in AuthContext
      setUser({
        ...user,
        isPremium: false
      });

      // Navigate to home page after successful registration
      navigate('/');
      
      // Call sendWelcomeEmail function
      console.log('Calling sendWelcomeEmail function');
      const functions = getFunctions();
      const sendWelcomeEmail = httpsCallable(functions, 'sendWelcomeEmail');
      const sendEmailResult = await sendWelcomeEmail({ email: user.email });
      console.log('sendWelcomeEmail result:', sendEmailResult);
    } catch (error) {
      console.error('Registration error:', error);
      if (error.code === 'auth/email-already-in-use') {
        setError('This email is already registered.');
      } else {
        setError(`Registration failed: ${error.message}`);
      }
    }
  };

  return (
    <div className="auth-container">
      <h2>Register</h2>
      <form onSubmit={handleRegister} className="auth-form">
        <input
          className="auth-input"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          required
        />
        <input
          className="auth-input"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          required
        />
        <button className="auth-button" type="submit">Register</button>
      </form>
      {error && (
        <p className="auth-error">
          {error}
          {error.includes('already registered')}
        </p>
      )}
      <p className="auth-link">Already have an account? <Link to="/login">Login here</Link></p>
    </div>
  );
}

export default Register;