import React, { useState } from 'react';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import './DownloadLink.css';

const DownloadLink = ({ fileType, filename, label, disabled }) => {
  const [error, setError] = useState(null);

  if (!fileType || !filename) return null;

  const handleDownload = async (e) => {
    e.preventDefault();
    try {
      const storage = getStorage();
      const storagePath = `highlights/${fileType}/${filename}`;
      const fileRef = ref(storage, storagePath);
      const url = await getDownloadURL(fileRef);

      const response = await fetch(url);
      if (!response.ok) throw new Error('Download failed');
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(downloadUrl);
    } catch (err) {
      console.error('Download error:', err);
      setError('Failed to download file. Please try again.');
    }
  };

  return (
    <div>
      <button 
        onClick={handleDownload}
        className={`process-button download-link ${disabled ? 'disabled' : ''}`}
        disabled={disabled}
      >
        {label || `Download ${fileType.toUpperCase()}`}
      </button>
      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default DownloadLink;