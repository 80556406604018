import React from 'react';
import { useAuth } from '../../context/AuthContext';
import './FileSelector.css';

const FileSelectorComponent = ({ file, onChange, maxFreeSize, maxPremiumSize }) => {
  const { user } = useAuth();
  const maxSize = user?.isPremium ? maxPremiumSize : maxFreeSize;
  const maxSizeMB = maxSize / (1024 * 1024);

  return (
    <div className="file-upload-wrapper">
      <input 
        type="file" 
        id="file" 
        accept=".mp3, .wav, .aac, .mp4, .mov, .avi" 
        onChange={onChange} 
        style={{display: 'none'}} 
      />
      <input
        type="text"
        className="file-selector"
        placeholder={`Select File (Max ${maxSizeMB}MB${user?.isPremium ? ' - Premium' : ''})`}
        value={file ? file.name : ''}
        onClick={() => document.getElementById('file').click()}
        readOnly
      />
    </div>
  );
};

export default FileSelectorComponent;