import React, { createContext, useContext, useCallback } from 'react';
import { analytics } from '../firebase-config';
import { logEvent } from 'firebase/analytics';

const AnalyticsContext = createContext();

export const AnalyticsProvider = ({ children }) => {
  const isProduction = process.env.NODE_ENV === 'production';

  const trackEvent = useCallback((eventName, eventParams) => {
    logEvent(analytics, eventName, eventParams);
    if (!isProduction) {
      console.log('Analytics Event:', eventName, eventParams);
    }
  }, [isProduction]);

  return (
    <AnalyticsContext.Provider value={{ trackEvent }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalytics = () => useContext(AnalyticsContext);