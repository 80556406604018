import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { createCheckoutSession } from '../../utils/stripe';
import './Upgrade.css';

function Upgrade() {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleUpgrade = useCallback(async () => {
    if (!user) {
      setError('No user logged in');
      return;
    }

    if (isLoading) return; // Prevent double submission

    setIsLoading(true);
    setError(null);

    try {
      await createCheckoutSession(user.uid);
    } catch (error) {
      console.error('Error creating checkout session:', error);
      setError('Failed to start the upgrade process. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [user, isLoading]);

  return (
    <div className="auth-container upgrade-container">
      <h2>Upgrade to Pocket Producer Premium</h2>
      <p>Upgrade now to enjoy the following benefits for just $49.95/month:</p>
      <ul>
        <li>Upload files up to 150MB</li>
        <li>Priority processing for your highlights</li>
        <li>Advanced AI-powered highlight selection</li>
        <li>Unlimited highlight generations per month</li>
        <li>Early access to new features</li>
        <li>Priority customer support</li>
      </ul>
      {error && <p className="error-message">{error}</p>}
      <button 
        onClick={handleUpgrade} 
        className="auth-button upgrade-button"
        disabled={isLoading}
      >
        {isLoading ? 'Processing...' : 'Upgrade Now'}
      </button>
      <p className="auth-link">Not ready to upgrade? <Link to="/">Return here</Link></p>
    </div>
  );
}

export default Upgrade;
