import React from 'react';
import { Link } from 'react-router-dom';
import SEO from '../SEO/SEO';
import '../../App.css';

const NotFound = () => {
  return (
    <div className="auth-container">
      <SEO title="404 - Page Not Found" description="The page you're looking for doesn't exist." />
      <h1 className="auth-title">404 - Page Not Found</h1>
      <p className="auth-description">Oops! The page you're looking for doesn't exist.</p>
      <Link to="/" className="auth-link">Go back to homepage</Link>
    </div>
  );
};

export default NotFound;