import React, { useContext } from 'react';
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase-config';
import { AuthContext } from '../../context/AuthContext';
import './UserNav.css';

function UserNav() {
  const { user } = useContext(AuthContext);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      // Redirect to login page or update state
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  if (!user) {
    return null;
  }

  return (
    <div className="user-nav">
      <span className="user-email">Logged in as: {user.email}</span>
      <button onClick={handleLogout} className="logout-button">Logout</button>
    </div>
  );
}

export default UserNav;