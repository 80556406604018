import React, { createContext, useState, useEffect, useContext } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { auth, db } from '../firebase-config';

export const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      setLoading(true);
      if (firebaseUser) {
        try {
          const userDocRef = doc(db, 'users', firebaseUser.uid);
          const userDoc = await getDoc(userDocRef);
          
          if (userDoc.exists()) {
            const userData = userDoc.data();
            const updatedUser = {
              ...firebaseUser,
              isPremium: userData.isPremium || false
            };
            setUser(updatedUser);
          } else {
            // If the user document doesn't exist, create it
            await setDoc(userDocRef, {
              email: firebaseUser.email,
              isPremium: false,
              createdAt: new Date()
            });
            setUser({ ...firebaseUser, isPremium: false });
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
          setUser(firebaseUser); // Set the user even if there's an error fetching Firestore data
        }
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ user, loading, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
