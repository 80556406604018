import React from 'react';
import './MessageDisplay.css';

function MessageDisplay({ message, isProcessing }) {
  if (!message) return null;

  return (
    <div className={`message ${isProcessing ? 'processing' : ''}`}>
      {message}
    </div>
  );
}

export default MessageDisplay;