import { analytics } from '../../firebase-config';
import { logEvent } from 'firebase/analytics';

export default function GoogleAnalytics() {
  // Log page view event
  logEvent(analytics, 'page_view', {
    page_path: window.location.pathname + window.location.search,
    page_location: window.location.href,
    page_title: document.title
  });

  return null;
}