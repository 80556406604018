import React from 'react';
import './HighlightOptions.css';

function HighlightOptions({ selectedMood, selectedDuration, speakerFilter, onMoodChange, onDurationChange, onSpeakerFilterChange }) {
  return (
    <div className="highlights-options">
      <div className="mood-selector">
        <label htmlFor="mood-select">Select Mood:</label>
        <select id="mood-select" value={selectedMood} onChange={onMoodChange}>
          <option value="Cinematic">Cinematic</option>
          <option value="Motivational">Motivational</option>
          <option value="Educational">Educational</option>
          <option value="Serious">Serious</option>
          <option value="Playful">Playful</option>
        </select>
      </div>
      <div className="duration-selector">
        <label htmlFor="duration-select">Select Duration:</label>
        <select id="duration-select" value={selectedDuration} onChange={onDurationChange}>
          <option value="15">15 seconds</option>
          <option value="30">30 seconds</option>
          <option value="60">60 seconds</option>
          <option value="90">90 seconds</option>
        </select>
      </div>
      <div className="speaker-selection">
        <label htmlFor="speaker-select">Select Speakers:</label>
        <select
          id="speaker-select"
          value={speakerFilter}
          onChange={(e) => onSpeakerFilterChange(e.target.value)}
          className="dropdown"
        >
          <option value="all">Include all speakers</option>
          <option value="noInterviewer">Exclude interviewer</option>
        </select>
      </div>
    </div>
  );
}

export default HighlightOptions;