import { combineReducers } from 'redux';

const initialState = {
    // Define your initial state
};

const rootReducer = (state = initialState, action) => {
    switch (action.type) {
        // Define your action types and handlers
        default:
            return state;
    }
};

export default combineReducers({
    root: rootReducer,
});
